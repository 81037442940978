import * as React from "react"
import NavBar from "./navbar"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <NavBar isLink={false}/>
    )

  } else {
    header = (
      <NavBar isLink={true}/>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        Copyright © Damian Budelewski {new Date().getFullYear()}
      </footer>
    </div>
  )
}

export default Layout
