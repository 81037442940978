import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import color from "color"
import { useEffect, useState } from "react";

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

import { FontAwesomeIcon as _FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faGithubAlt,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

config.autoAddCss = false

const FontAwesomeIcon = styled(_FontAwesomeIcon)`
  color: ${props => props.theme.primary};
`
const EgoSection = styled.section`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  margin-bottom: 64px;
  h1 {
    font-size: 3em;
    color: ${props => props.theme.text};
  }
  p {
    color: ${props =>
      color(props.theme.text)
        .alpha(0.7)
        .toString()};
  }
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
`


// Name on left side of the container, with social media buttons on the right
const IntroName = styled.a`
  font-size: 2em;
  margin-top: 6px;
  color: ${props => props.theme.text};
  margin-right: auto;
  text-decoration: none;
  &:hover {
    color: ${props =>
      color(props.theme.text)
        .alpha(0.7)
        .toString()};
  }
`

const SocialButton = styled.a`
  background: ${props => props.theme.accent};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-top: 0px;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 0px 1px
      ${props =>
        color(props.theme.accent)
          .darken(0.1)
          .toString()},
    0px 0px 4px ${props => props.theme.accent};
  &:hover {
    background: ${props =>
      color(props.theme.accent)
        .alpha(0.1)
        .toString()};
  }
`


const NavBar = (props) => {
  const isBrowser = typeof window !== "undefined"

  const [screenWidth, setScreenWidth] = useState(isBrowser ? window.innerWidth : 0);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    if (props.isLink) {
      return (
       <>
         <EgoSection style={{ flexDirection: screenWidth > 600 ? "row" : "column" }}>
           <IntroName style={{ 
             marginTop: screenWidth > 600 ? "0px" : "-10px",
             fontSize: screenWidth > 600 ? "2em" : "1.5em",
             marginLeft: screenWidth > 600 ? "0px" : "auto",
           }}>
              <Link to="/" >
                Back to main page
              </Link>
           </IntroName>
           <Wrapper style={{ marginTop: screenWidth > 600 ? "0px" : "30px" }} >
             <SocialButton
               href="https://github.com/damianbudelewski"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faGithubAlt} />
             </SocialButton>
             <SocialButton
               href="https://twitter.com/budelewski_d"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faTwitter} />
             </SocialButton>
             <SocialButton
               href="https://linkedin.com/in/damianbudelewski"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faLinkedin} />
             </SocialButton>
           </Wrapper>
         </EgoSection>
       </>
      );
    } else if (!props.isLink) {
      return (
       <>
         <EgoSection style={{ flexDirection: screenWidth > 600 ? "row" : "column" }}>
           <IntroName style={{ 
             marginTop: screenWidth > 600 ? "0px" : "-10px",
             fontSize: screenWidth > 600 ? "2em" : "1.5em",
             marginLeft: screenWidth > 600 ? "0px" : "auto",
           }}>
             Damian Budelewski
           </IntroName>
           <Wrapper style={{ marginTop: screenWidth > 600 ? "0px" : "30px" }} >
             <SocialButton
               href="https://github.com/damianbudelewski"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faGithubAlt} />
             </SocialButton>
             <SocialButton
               href="https://twitter.com/budelewski_d"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faTwitter} />
             </SocialButton>
             <SocialButton
               href="https://linkedin.com/in/damianbudelewski"
               target="_blank"
               rel="noreferrer"
             >
               <FontAwesomeIcon size="1x" icon={faLinkedin} />
             </SocialButton>
           </Wrapper>
         </EgoSection>
       </>
     );
    }
  else return null
}

export default NavBar
